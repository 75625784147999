import React from 'react';
import '../../styles/buttonNeon.css';

const BannerServices = ({ title, description, buttonText }) => {
  return (
    <div className="flex justify-center bg-[#f4f4f4] items-center p-6 md:p-10">
      <div className="flex flex-col lg:flex-row bg-black shadow-lg rounded-lg text-white max-w-4xl w-full">
        <div className="flex items-center justify-center p-6 md:p-10 bg-gradient-to-r from-[#004AAD] to-[#CB6CE6] rounded-lg">
          <p className="text-xl md:text-2xl lg:text-3xl font-bold text-center lg:text-left">{title}</p>
        </div>
        <div className="text-white p-6 md:p-10 relative flex justify-center bg-black rounded-lg">
          <div className="text-center">
            <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4">{title}</h2>
            <p className="text-sm md:text-base mb-6">{description}</p>
            <a
              href="xxx"
              className="glow-on-hover bg-white bg-opacity-50 text-black font-bold py-2 px-4 rounded-lg"
            >
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerServices;
