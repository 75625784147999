import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const BannerSoluctions = ({ bannerItems }) => {
  return (
    <div className="flex flex-col items-center bg-[#131112] text-white px-4">
      <div className="md:m-64 md:mt-0 md:mb-0 w-full">
        <h1 className="text-3xl font-bold mb-5 text-center" style={{ fontFamily: 'Poppins, sans-serif' }}>
          Soluções Personalizáveis Para Todas As Empresas
        </h1>
        <div className="w-full px-4 max-w-screen-lg mx-auto">
          <div className="block md:hidden mb-10">
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              className="w-full"
            >
              {bannerItems.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="text-center p-5 rounded-lg transition duration-300 hover:bg-white/20 hover:backdrop-filter hover:backdrop-blur-lg bg-opacity-90 bg-[#131112] mx-auto w-full max-w-md">
                    <div className="z-3 p-5 rounded-lg">
                      <div className="mb-5">
                        <img
                          src={item.icon}
                          alt={`Ícone ${index + 1}`}
                          className="h-20 w-20 md:h-20 md:w-20 object-cover mx-auto"
                        />
                      </div>
                      <h2 className="text-lg font-semibold mb-2" style={{ fontFamily: 'Poppins, sans-serif' }}>{item.title}</h2>
                      <p style={{ fontFamily: 'Montserrat, sans-serif' }}>{item.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={`hidden md:grid gap-4 mb-20 ${
            bannerItems.length < 3 ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-2 justify-center' : 'grid-cols-1 md:grid-cols-3 lg:grid-cols-3'
          }`}>
            {bannerItems.map((item, index) => (
              <div
                key={index}
                className="text-center p-5 rounded-lg transition duration-300 hover:bg-white/20 hover:backdrop-filter hover:backdrop-blur-lg bg-opacity-90 bg-[#131112] max-w-xs mx-auto"
              >
                <div className="z-5 p-2 rounded-lg">
                  <div className="mb-2">
                    <img
                      src={item.icon}
                      alt={`Ícone ${index + 1}`}
                      className="h-24 w-24 md:h-20 md:w-20 object-cover mx-auto"
                    />
                  </div>
                  <h2 className="text-lg font-semibold mb-2" style={{ fontFamily: 'Poppins, sans-serif' }}>{item.title}</h2>
                  <p style={{ fontFamily: 'Anonymous Pro, sans-serif' }}>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSoluctions;
