import React from 'react';
import BannerInfo from "../Components/BannerInfo/BannerInfo";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import TextInfo from "../Components/TextInfo/TextInfo";
import { DataTerms, bgColorTerms, linkContactTerms, navLinksTerms, soluctionLinksTermos, textColorTerms, titleBannerTerms } from "./Config";
import logo from './../Images/Icone_Fundo_Transparente.webp';

const Terms = () => {
    return (
        <>
            <Header
                bgColor={bgColorTerms}
                logo={logo}
                linkContact={linkContactTerms}
                textColor={textColorTerms}
                soluctionLinks={soluctionLinksTermos}
                logoSize="w-15 h-20 p-2"
                navLinks={navLinksTerms}
            />
            <BannerInfo
                title={titleBannerTerms}
                textColor={textColorTerms}
            />
            <TextInfo sections={DataTerms} />
            <Footer />
        </>
    )
}

export default Terms;
