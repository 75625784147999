import ErrorComponent from "../Components/ErrorMsg/ErrorMsg";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import logo from './../Images/BREACH_BUSTER_HACKING_CLUB__1_-removebg-preview.webp';
import imageSrc from './../Images/Under construction-bro.png';
import { bgColorHeaderTE, linkHeaderTE, navLinksEstresse, soluctionLinksTE, textColorTE } from "./Config";

const TesteDeEstresse = () => {
    return (
        <>
            <Header logo={logo}
                bgColor={bgColorHeaderTE}
                linkContact={linkHeaderTE}
                textColor={textColorTE}
                soluctionLinks={soluctionLinksTE}
                logoSize="md:w-25 md:h-25 md:p-2 w-20 h-20"
                navLinks={navLinksEstresse} />
            <div className="flex justify-center items-center mt-20 bg-[#f4f4f4]">
                <div className="flex flex-col items-center mt-32 mb-20 p-4 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border border-gray-200 rounded-lg shadow-lg">
                    <img src={imageSrc} alt="Em Construção" className="w-64 h-64 mb-4" />
                    <div className="p-4">
                        <a href="/" className="glow-on-hover text-center text-black">
                            Esse Serviço Estará disponível Em Breve. Aguarde!
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TesteDeEstresse;