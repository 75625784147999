import React from 'react';

const Identified = () => {
    return (
        <div 
            className="text-black py-12 px-6 text-center bg-[#f4f4f4]" style={{ fontFamily: 'Montserrat, sans-serif'}}>
            <h1 className="text-5xl md:text-6xl font-bold mb-4">Se identificou?</h1>
            <p className="text-lg md:text-2xl mb-2">Gostaria de fazer parte do time?</p>
            <p className="text-lg md:text-2xl mb-2">Nossos valores batem com os seus?</p>
            <p className="text-lg md:text-2xl mb-2">Quer tornar o mundo digital mais seguro?</p>
            <p className="text-lg md:text-2xl mb-6">Entre em contato para tomar a melhor decisão!</p>
            <div className="mt-4">
                <a 
                    href="https://www.linkedin.com/company/breachbusters/" 
                    className="px-8 py-2 text-lg font-bold bg-[#000] shadow-md hover:shadow-[#D01B9B] text-white rounded-lg"
                >
                    Join Us
                </a>
            </div>
        </div>
    );
}

export default Identified;
