import React from 'react';
import BannerMain from '../Components/BannerMain/BannerMain';
import BannerTs from '../Components/BannerSoluctions/BannerSoluctions';
import Header from '../Components/Header/Header';
import Contact from '../Components/Contact/Contact';
import BannerServices from '../Components/BannerServices/BannerServices';
import Footer from '../Components/Footer/Footer';
import BenefictsServices from '../Components/BenefictsServices/BenefictsServices';
import Methodologies from '../Components/Methodologies/Methodologies';
import { MethodologiesData, MethodologiesDataTI, TitleHomeTI, bannerItems, benefitsData, bgColorHeader, bgColorTIHeader, bgColorTIHome, bgColorTreinamentos, ethicalHackingContent, homeTextTI, homeTitle, linkHeaderTI,  navLinksIntrusao,  soluctionLinksTI, textColorTI, textFormTI } from './Config';
import BannerSoluctions from '../Components/BannerSoluctions/BannerSoluctions';
import logo from './../Images/BREACH_BUSTER_HACKING_CLUB__1_-removebg-preview.webp'


const TesteDi = () => {
  return (
    <>
      <Header logo={logo}
      bgColor={bgColorTIHeader}
        linkContact={linkHeaderTI}
        textColor={textColorTI}
        soluctionLinks={soluctionLinksTI}
        logoSize="md:w-25 md:h-25 md:p-2 w-20 h-20" 
        navLinks={navLinksIntrusao}/>
      <BannerMain bgColor={bgColorTIHome}
        title={TitleHomeTI}
        text={homeTextTI}/>
      <BannerSoluctions bannerItems={bannerItems}/>
      <BenefictsServices benefitsData={benefitsData} />
      <Methodologies content={MethodologiesDataTI} />
      <Contact textForm={textFormTI} />
      <BannerServices {...ethicalHackingContent}/>
      <Footer />
    </>
  );
}

export default TesteDi;
