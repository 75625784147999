import React, { useState, useEffect } from 'react';
import { HiOutlineMenu, HiX } from 'react-icons/hi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Header = ({ logo, bgColor, textColor, linkContact, navLinks = [], logoSize }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleScrollTo = (id) => {
        setMenuOpen(false);
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    };

    const handleLogoClick = () => {
        setMenuOpen(false);
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleContactClick = (event) => {
        event.preventDefault();
        const currentPage = location.pathname.replace('/', '');
        if (currentPage === 'About') {
            navigate('/#contact');
        } else {
            window.location.href = linkContact;
        }
    };

    useEffect(() => {
        if (location.hash === '#contact') {
            handleScrollTo('contact');
        }
    }, [location]);

    return (
        <header className={`fixed top-0 left-0 w-full ${bgColor} z-50 shadow-lg text-center`}>
            <div className={`container mx-auto flex justify-between items-center py-2 px-4 md:py-2 md:px-32 lg:px-28 xl:px-48 ${textColor}`}>
                <div className="flex items-center">
                    <div onClick={handleLogoClick} className="cursor-pointer">
                        <img src={logo} alt="Logo" className={logoSize} />
                    </div>
                    <div className={`hidden md:block mx-2 border-l-2 md:h-10 md:h-12 ${textColor}`}></div>
                    <div onClick={handleLogoClick} className="cursor-pointer">
                        <h1 className="text-lg md:text-2xl font-bold tracking-wide ml-1" style={{ fontFamily: 'Tomorrow, sans-serif' }}>
                            Breach Busters
                        </h1>
                    </div>
                </div>
                <nav className="hidden md:flex space-x-6 md:text-xl lg:space-x-8 font-bold items-center md:ml-20" style={{ fontFamily: 'Anonymous Pro, sans-serif' }}>
                    {navLinks.map((link, index) => (
                        <Link key={index} to={link.href} className="relative" onClick={() => handleScrollTo(link.scrollToId)}>
                            {link.text}
                            <span className={`block h-0.5 absolute left-0 bottom-0 w-0 group-hover:w-full transition-all duration-200 ${textColor}`}></span>
                        </Link>
                    ))}
                    <div className='py-2 px-4 rounded-lg bg-[#d11c9b] text-xl shadow-md hover:shadow-lg transition duration-300'>
                        <a href={linkContact} className="text-black " onClick={handleContactClick}>
                            Entre Em Contato
                        </a>
                    </div>
                </nav>
                <div className="block md:hidden">
                    <button onClick={() => setMenuOpen(!menuOpen)} aria-label="Toggle menu" className="focus:outline-none">
                        {menuOpen ? <HiX className="w-8 h-8" /> : <HiOutlineMenu className="w-8 h-8" />}
                    </button>
                </div>
            </div>
            <div className={`fixed top-0 right-0 h-full max-w-xs w-full ${textColor} bg-opacity-90 ${bgColor} shadow-lg transform ${menuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:hidden z-20 backdrop-blur-lg`}>
                <div className="flex justify-between items-center px-6 py-8">
                    <div onClick={handleLogoClick} className="flex items-center cursor-pointer">
                        <img src={logo} alt="Logo" className="w-10 h-15" />
                        <h1 className="text-lg font-bold tracking-wide ml-2" style={{ fontFamily: 'Tomorrow, sans-serif' }}>
                            Breach Busters
                        </h1>
                    </div>
                    <button onClick={() => setMenuOpen(false)} aria-label="Close menu" className="focus:outline-none">
                        <HiX className="w-8 h-8 text-white" />
                    </button>
                </div>
                <nav className={`flex flex-col items-center py-4 mt-4 ${textColor}`}>
                    <ul className="flex flex-col space-y-4 text-center font-bold" style={{ fontFamily: 'Anonymous Pro, sans-serif' }}>
                        {navLinks.map((link, index) => (
                            <li key={index}>
                                <Link to={link.href} onClick={() => { handleScrollTo(link.scrollToId); setMenuOpen(false); }}>
                                    {link.text}
                                </Link>
                            </li>
                        ))}
                        <li>
                            <div className='py-2 px-8 rounded-lg bg-gradient-to-r from-purple-400 to-blue-400 hover:from-purple-600 hover:to-blue-600 shadow-md hover:shadow-lg transition duration-300'>
                                <a href={linkContact} className="text-white " onClick={handleContactClick}>
                                    Entre Em Contato
                                </a>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
            {menuOpen && <div className="fixed inset-0 bg-black opacity-50 z-10 md:hidden" onClick={() => setMenuOpen(false)}></div>}
        </header>
    );
};

export default Header;
