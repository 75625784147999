import React from 'react';
import illustration from '../../Images/cyber-attack-pana.webp';
import { ReactTyped as Typed } from 'react-typed'; 

const BannerMain = ({ bgColor, title, text, subTitle }) => {
  return (
    <div className={`flex justify-center items-center ${bgColor} text-white md:mt-8 max-h-5/6`}>
      <div className="container mx-auto px-4 md:px-44 mb-8 mt-24 max-w-screen-xl">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="md:w-1/2 text-center md:text-left md:mx-8">
            <h1 className="text-5xl md:text-7xl font-bold mt-0" style={{ fontFamily: 'Anonymous Pro, sans-serif'}}>
              {title}
            </h1>
            <h2 className="text-4xl md:text-5xl font-bold md:mb-4 text-[#d11c9b]" style={{ fontFamily: 'Anonymous Pro, sans-serif' }}>
              {subTitle}
            </h2>
            <p className="mb-6" style={{ fontFamily: 'Montserrat, sans-serif' }}>
              {text}
            </p>
            <a href="#contact" className="bg-[#d11c9b] text-black font-bold py-2 px-4 rounded-lg inline-block">
              BECOME BREACHLESS
            </a>
          </div>
          <div className="md:w-1/2 flex items-center justify-center mt-8 md:mt-0">
            <img src={illustration} alt="Illustration" className="w-36 h-36 md:w-5/6 md:h-5/6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerMain;
