//Imports 
import IconRisk from './../Images/risks.webp';
import IconSecurity from './../Images/security.webp';
import IconBin from './../Images/risks.webp';
import IconCompliance from './../Images/reliability.webp';
import IconCredibility from './../Images/badge.webp';
import IconDifferential from './../Images/horse.webp';
import IconTrust from './../Images/handshake.webp';
import IconTimeline from '../Images/timeline.png';
import IconOnline from '../Images/online-class.png';
import recession from './../Images/recession.webp';
import up_arrow from './../Images/up-arrow.webp';
import logout from './../Images/logout (1).webp';
import ampulheta from './../Images/sandglass.webp';
import foco from './../Images/target.webp';
import escudo from './../Images/shield-1.webp';
import img1 from './../Images/hacker.png';
import img2 from './../Images/book.png';
import img3 from './../Images/data-analytics.png';
import img4 from './../Images/sword.png';
import img5 from './../Images/send-message.png';
import img6 from './../Images/access-denied.png';
import medical from './../Images/medical-app (1).webp';
import profile from './../Images/profile (1).webp';


//Main page
export const homeTitleOne = "Start Bust";
export const homeTitleTwo = "Into The";
export const bgColorHome = "bg-[#000000]";
export const bgColorHeader = "bg-[#000000]";
export const textColorHeader = "text-white"
export const linkHeader = "#contact";
export const subTitle = ['Unexplored.', 'Breaches.', 'Possibilities.', 'Future.'];
export const navLinksHome = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '#solutions', scrollToId: 'solutions' }
  ];
export const homeText = "Conte com profissionais dedicados para identificar brechas no seu ambiente e receba relatórios personalizados com informações relevantes para o seu negócio!";
export const soluctionCards = [
    { id: 1, title: 'Teste de intrusão', image: img1, link: '/Solutions/Teste-de-intrusao', description: 'Simule ataques para identificar brechas no seu sistema.' },
    { id: 2, title: 'Treinamentos', image: img2, link: '/Solutions/treinamentos', description: 'Capacite sua equipe para seguir as melhores praticas de segurança.' },
    { id: 3, title: 'Engenharia Reversa', image: img3, link: '/Solutions/engenharia-reversa', description: 'Revise seu software para verificação de vunerabilidades.' },
    { id: 4, title: 'Red Team', image: img4, link: '/Solutions/red-team', description: 'Contrate uma equipe para simular um ataque real em seu ambiente.' },
    { id: 5, title: 'Phishing', image: img5, link: '/Solutions/pishing', description: 'Teste de phishing para mensurar a segurança do seu negocio.' },
    { id: 6, title: 'Teste de estresse', image: img6, link: '/Solutions/teste-de-estresse', description: 'Verifique a capacidade do seu sistema em lidar com grandes Cargas.' }
];

export const formTextMain = "Não precisamos convencer você e ​seu time que a segurança é ​primordial, mas precisamos que ​você escolha o time certo para o ​trabalho, insira seu e-mail para ​conversarmos sobre o escopo do ​nosso novo desafio!";
export const formTitleMain = "Entre em contato com nossos especialistas!"

export const soluctionLinks = [
    { href: '/Solutions/teste-de-intrusao', text: 'Teste de intrusão' },
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/red-team', text: 'Red Team' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];

//About Page
export const aboutTitle = "Buscando um mundo digitalmente seguro...";
export const AboutbgColorHeader = "bg-[#000000]";
export const AboutBgColorHome = "bg-[#000000]";
export const textColorAbout = "text-white";
export const subTitleAbout = [''];
export const linkHeaderAbout = "/#contact";
export const homeTextAbout = "Conte com profissionais dedicados para identificar brechas no seu ambiente e receba relatórios personalizados com informações relevantes para o seu negócio!";
export const navLinksAbout = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];
export const soluctionLinksAbout = [
    { href: '/Solutions/teste-de-intrusao', text: 'Teste de intrusão' },
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/red-team', text: 'Red Team' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];

//Teste de intrusão
export const TitleHomeTI = "Teste De Intrusão.";
export const bgColorTIHeader = "bg-[#131112]";
export const bgColorTIHome = "bg-[#131112]";
export const textColorTI = "text-white"
export const linkHeaderTI = "#contact";
export const subTitleTI = "";
export const textFormTI = "Agora que você viu a importância do pentesting para seu negócio, vamos conversar a respeito.";
export const navLinksIntrusao = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];

export const homeTextTI = "Ao digitalizar sua presença, você se expõe a riscos invisíveis, com potenciais vulnerabilidades sendo alvo de cibercriminosos, ameaçando seus clientes, parceiros e a operação do negócio. Para atender aos padrões regulatórios e proteger sua reputação, é crucial ter uma equipe de especialistas em segurança cibernética. Eles aplicam técnicas avançadas e conhecimento especializado para identificar e corrigir vulnerabilidades, fortalecendo sua infraestrutura digital.";
export const benefitsData = {
  title: "Benefícios",
  description: "",
  cardWidth: 'w-72',
  cardHeight: 'h-96',
  benefits: [
    {
      icon: IconRisk,
      title: 'Riscos',
      description: 'Ao identificar e corrigir as vulnerabilidades descobertas durante os testes de penetração, as empresas podem diminuir as chances de suas organizações serem bem sucedidas no futuro.'
    },
    {
      icon: IconSecurity,
      title: 'Segurança',
      description: 'Realizar testes de penetração mostra um comprometimento proativo com a segurança cibernética. Identificando e corrigindo vulnerabilidades antes que invasores possam explorá-las, fortalecendo a segurança de dados.'
    },
    {
      icon: IconCompliance,
      title: 'Conformidade',
      description: 'Muitos setores têm regulamentações estritas de segurança cibernética. Realizar testes de penetração ajuda a garantir que as empresas estejam em conformidade com essas normas, demonstrando o compromisso com a segurança.'
    },
    {
      icon: IconCredibility,
      title: 'Credibilidade',
      description: 'Empresas que se submetem a testes de penetração ganham mais credibilidade com os clientes, demonstrando um compromisso com a proteção dos dados dos clientes.'
    },
    {
      icon: IconDifferential,
      title: 'Diferencial',
      description: 'Empresas que podem afirmar que seus sistemas foram testados para vulnerabilidades podem usar isso como um diferencial de mercado, aumentando a confiança dos clientes e demonstrando um compromisso com a segurança.'
    },
    {
      icon: IconTrust,
      title: 'Confiança',
      description: 'O teste de penetração destaca o objetivo de proteger dados e sistemas críticos, reforçando a confiança dos clientes existentes e ajudando a conquistar novos clientes e fortalecer os relacionamentos de confiança e fidelidade.'
    }
  ]
};


export const MethodologiesDataTI = [
    {
        title: 'Metodologia',
        content: [
            'Ao adotar frameworks consolidados, os profissionais de segurança podem acelerar o processo de teste, garantir uma cobertura abrangente e facilitar a automação de tarefas repetitivas. Isso resulta em uma identificação mais rápida e precisa de vulnerabilidades, permitindo que as organizações tomem medidas proativas para fortalecer suas posturas de segurança cibernética.',
            '• OWASP – Open Web Application Security Project – Organizações dedicadas à segurança de aplicações web, oferecendo diretrizes e ferramentas para proteger contra ameaças.',
            '• PTES – Penetration Testing Execution Standard – Um padrão para conduzir testes de penetração de forma consistente e abrangente.',
            '• NIST – National Institute of Standards and Technology – É um framework desenvolvido pelo Breach Busters para testes de penetração e espionagem.'
        ]
    },
    {
        title: 'Projeto',
        content: [
            'O funcionamento do projeto ocorre de maneiras simples para o cliente e complexas nos bastidores. Conseguimos abstrair as partes difíceis em algo simples de se entender, permitindo que nossos clientes tenham o poder de tomar uma decisão assertiva.',
            '• ESCOPO – Você irá ver quais domínios estão em avaliação de testes, reagendando horas, dias e semanas, explicando o que há em algum cenário.',
            '• AVALIAÇÃO – Avaliação de que maneira poderiam ser implementados contra uma avaliação, usando nossas próprias técnicas de implantações realística.',
            '• RESULTADO – É agendada uma data/horário para gerar, BOA e INF, demandando serem traduzidas de forma clara para torná-las com dados de decisões e debates para o time técnico.'
        ]
    },
    {
        title: 'Report',
        content: [
            'Os relatórios oferecem uma comunicação clara e precisa dos resultados dos testes, ajudando a identificar facilmente vulnerabilidades críticas, priorizar correções e justificar investimentos em segurança. Além disso, fornecem informações valiosas para apoiar a tomada de decisões estratégicas, promovendo a segurança da informação dentro da organização. Com relatórios abrangentes e detalhados, você terá um visão clara dos riscos enfrentados e das medidas necessárias para proteger sua organização.',
            '• Relatórios Técnicos e Gerenciais – Contendo todas as etapas dos testes e suas sugestões.',
            '• Relatórios para Tomadores de Decisão – Exclusivos para os tomadores de decisão que necessitam de informação legível e eficiente.',
            '• Relatórios em PowerPoint – Apresentação de slides para que o nosso team possa explicar o que precisa ser feito, já com etapas e atividades dentro.'
        ]
    }
];

export const ethicalHackingContent = {
    title: "APIs e a facilidade de hackear.",
    description: "Entenda por que as APIs facilitam a vida dos agentes maliciosos. Se uma vez era normal realizar toda uma sequência de passos para obter informação sensível, hoje basta uma requisição.",
    buttonText: "VEJA COMO AS APIS SÃO ALVOS FÁCEIS",
    buttonLink: "/",
    backgroundLeft: "black",
    backgroundRight: "linear-gradient(to right, #3E4A89, #6B468C)"
};

export const bannerItems = [
    {
        icon: recession,
        title: '15%',
        description: 'de aumento do custo médio global de uma violação de dados em 3 anos. Em 2023 foi o prejuízo calculado foi de 4,45 milhões de dólares!'
    },
    {
        icon: up_arrow,
        title: '3x',
        description: 'menos chances de sofrer uma violação de segurança caso sua empresa realize um teste de penetração.'
    },
    {
        icon: logout,
        title: '35%',
        description: 'das brechas envolvem algum tipo de falha técnica, como vulnerabilidades de software ou configurações inadequadas.'
    }
];

export const soluctionLinksTI = [
    { href: '/Solutions/teste-de-intrusao', text: 'Teste de intrusão' },
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/red-team', text: 'Red Team' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];

//Engenharia reversa
export const TitleHomeER = "Engenharia Reversa.";
export const bgColorERHeader = "bg-[#131112]";
export const bgColorERHome = "bg-[#131112]";
export const textColorER = "text-white"
export const linkHeaderER = "#contact";
export const subTitleER = "";
export const navLinksEngenharia = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];

export const formTextER = "Com profissionais experimentes e qualificados a analise do seu aplicativo esta segura conosco e estara segura para o mercado, entre em contato para mais detalhes";
export const formTitleER = "Vamos analisar seu aplicativo.";

export const homeTextER = "Ao aplicar a engenharia reversa, é possível desmontar e analisar cuidadosamente sistemas e softwares existentes, revelando seu funcionamento interno e identificando possíveis vulnerabilidades. Essa abordagem estratégica oferece uma compreensão profunda das tecnologias utilizadas em sua infraestrutura digital, permitindo que você fortaleça suas defesas contra potenciais ataques cibernéticos.Além disso, ao entender os sistemas de dentro para fora, você pode desenvolver e implementar soluções de segurança mais eficazes e adaptadas às necessidades específicas de sua organização. A engenharia reversa não apenas ajuda a proteger seus dados e operações, mas também contribui para o cumprimento de regulamentações e padrões de segurança, garantindo a integridade e confiabilidade de seu ambiente digital.";
export const benefitsDataER = {
    title: "Beneficios",
    description: "Ao simular ataques controlados, é possível identificar e corrigir vulnerabilidades antes que sejam exploradas por hackers maliciosos, mitigando riscos e protegendo seus dados e dos seus parceiros. Além disso ajuda a avaliar sua postura de segurança, cumprir requisitos regulatórios, proteger sua reputação e aumentar a conscientização da equipe sobre práticas seguras de computação.",
    cardWidth: 'w-72',
    cardHeight: 'h-96',
    benefits: [
      {
        icon: IconBin,
        title: 'Verificação Antecipada',
        description: 'Ao realizar engenharia reversa, é possível identificar antecipadamente possíveis vulnerabilidades nos sistemas, o que permite que sejam corrigidas antes de serem exploradas por atacantes.'
      },
      {
        icon: IconSecurity,
        title: 'Melhoria da Segurança',
        description: 'A realização de testes de segurança específicos por meio da engenharia reversa pode atestar aos stakeholders que a aplicação foi submetida a um processo de avaliação rigoroso antes do lançamento do produto, aumentando a confiança na segurança do mesmo.'
      },
      {
        icon: IconCompliance,
        title: 'Conformidade Regulatória',
        description: 'A engenharia reversa contribui para o cumprimento de regulamentações e padrões de segurança, garantindo que a organização esteja em conformidade com as exigências legais.'
      }
    ]
  };
  

export const MethodologiesDataER = [
    {
        title: 'Metodologia',
        content: [
            'MITRE ATT&CK - Uma base de conhecimento detalhada que mapeia táticas, técnicas e procedimentos usados por adversários em ciberataques. Ele ajuda as equipes Red Team a replicar ataques reais com precisão, oferecendo uma visão abrangente das potenciais ameaças.',
            'CBEST - Um framework desenvolvido pelo Bank of England que oferece uma abordagem padronizada para realizar testes Red Team em instituições financeiras. Ele foca em ameaças reais e ajuda a identificar fraquezas significativas na infraestrutura de segurança.',
            'OSSTMM - Open Source Security Testing Methodology Manual, um framework desenvolvido pelo Institute for Security and Open Methodologies (ISECOM).'
        ]
    },
    {
        title: 'Projeto',
        content: [
            'O funcionamento do projeto ocorre de maneira simples para o cliente e complexa nos bastidores. Conseguimos abstrair as partes difíceis em algo simples de se entender, permitindo que nossos clientes tenham o poder de tomar uma decisão assertiva.',
            'ESCOPO - Você e sua equipe definem quais são os artefatos de testes, regulando horas, dias, servidores, aplicações e o que mais julgarem necessário.',
            'OPERAÇÃO - Nosso time de especialistas realiza o pentesting mantendo contato sobre o andamento da operação e demais informações necessárias.',
            'RESULTADO - É agendada uma data final para entrega do RAT, ROC e HF, demonstrando com exatidão de forma clara para tomadores de decisões e detalhada para o time técnico.'
        ]
    },
    {
        title: 'Report',
        content: [
            'Os relatórios oferecem uma comunicação clara e precisa dos resultados dos testes, ajudando a identificar facilmente vulnerabilidades críticas, priorizar correções e justificar investimentos em segurança. Além disso, fornecem informações valiosas para apoiar a tomada de decisões estratégicas, promovendo transparência e responsabilidade em todo o processo. Com relatórios abrangentes em mãos, você terá uma visão clara das ameaças enfrentadas e das medidas necessárias para proteger sua organização.',
            'RAT - Relatório de Análise Técnica - Confeccionado para o time que faz o seu negócio acontecer, mostrando detalhes bit a bit.',
            'ROC - Resumo de Operações Cibernéticas - Exclusivo para os tomadores de decisão que precisam da informação rápida e precisa.',
            'HF - Highlight Founds - Uma apresentação com seu time e o nosso para explicar o que foi encontrado e tirar eventuais dúvidas.'
        ]
    }
];

export const ethicalHackingContentER = {
    title: "APIs e a facilidade de hackear.",
    description: "Entenda por que as APIs facilitam a vida dos agentes maliciosos. Se uma vez era normal realizar toda uma sequência de passos para obter informação sensível, hoje basta uma requisição.",
    buttonText: "VEJA COMO AS APIS SÃO ALVOS FÁCEIS",
    buttonLink: "/",
    backgroundLeft: "black",
    backgroundRight: "linear-gradient(to right, #3E4A89, #6B468C)"
};

export const soluctionLinksER = [
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/red-team', text: 'Red Team' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];


//Red Team
export const TitleHomeRT = "Red Team";
export const bgColorRTHeader = "bg-[#131112]";
export const bgColorRTHome = "bg-[#131112]";
export const linkHeaderRT = "#contact";
export const textColorRT = "text-white"
export const subTitleRT = "";
export const homeTextRT = "Ao realizar ataques simulados, a Red Team identifica pontos fracos em sua infraestrutura de segurança, proporcionando uma visão realista das ameaças. Essa abordagem proativa não só protege seus clientes, parceiros e operações, mas também assegura o cumprimento de padrões regulatórios. Com técnicas sofisticadas e expertise em cibersegurança, a Red Team trabalha para antecipar possíveis ataques, permitindo a implementação de medidas defensivas eficazes e reforçando a segurança do seu ambiente digital.";
export const navLinksRedTeam = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];

export const formTextRD = "Vamos agir no seu negócio, forneça seu e-mail para entendermos melhor nossa missão.";
export const formTitleRD = "Nosso red team está ao seu comando.";

export const bannerItemsRT = [
    {
        icon: ampulheta,
        title: '60%',
        description: 'é o percentual reduzido para a detecção de ataques cibernéticos reais.'
    },
    {
        icon: foco,
        title: '30%',
        description: 'de aumento na maturidade de todo seu ecosistema envolvendo segurança.'
    },
    {
        icon: escudo,
        title: '78%',
        description: 'a mais de resiliência organizacional contra ataques cibernéticos.'
    }
];

export const benefitsDataRT = {
    title: "Beneficios",
    description: "Ao realizar ataques simulados, a Red Team identifica pontos fracos em sua infraestrutura de segurança, proporcionando uma visão realista das ameaças. Essa abordagem proativa não só protege seus clientes, parceiros e operações, mas também assegura o cumprimento de padrões regulatórios. Com técnicas sofisticadas e expertise em cibersegurança, a Red Team trabalha para antecipar possíveis ataques, permitindo a implementação de medidas defensivas eficazes e reforçando a segurança do seu ambiente digital.",
    cardWidth: 'w-72',
    cardHeight: 'h-96',
    benefits: [
      {
        icon: IconRisk,
        title: 'Riscos',
        description: 'Ao identificar e corrigir as vulnerabilidades descobertas durante os testes de penetração, as empresas podem diminuir as chances de suas organizações serem bem sucedidas no futuro.'
      },
      {
        icon: IconSecurity,
        title: 'Segurança',
        description: 'Realizar testes de penetração mostra um comprometimento proativo com a segurança cibernética. Identificando e corrigindo vulnerabilidades antes que invasores possam explorá-las, fortalecendo a segurança de dados.'
      },
      {
        icon: IconCompliance,
        title: 'Conformidade',
        description: 'Muitos setores têm regulamentações estritas de segurança cibernética. Realizar testes de penetração ajuda a garantir que as empresas estejam em conformidade com essas normas, demonstrando o compromisso com a segurança.'
      },
      {
        icon: IconCredibility,
        title: 'Credibilidade',
        description: 'Empresas que se submetem a testes de penetração ganham mais credibilidade com os clientes, demonstrando um compromisso com a proteção dos dados dos clientes.'
      },
      {
        icon: IconDifferential,
        title: 'Diferencial',
        description: 'Empresas que podem afirmar que seus sistemas foram testados para vulnerabilidades podem usar isso como um diferencial de mercado, aumentando a confiança dos clientes e demonstrando um compromisso com a segurança.'
      },
      {
        icon: IconTrust,
        title: 'Confiança',
        description: 'O teste de penetração destaca o objetivo de proteger dados e sistemas críticos, reforçando a confiança dos clientes existentes e ajudando a conquistar novos clientes e fortalecer os relacionamentos de confiança e fidelidade.'
      }
    ]
  };
  

export const MethodologiesDataRT = [
    {
        title: 'Metodologia',
        content: [
            'Ao adotar frameworks consolidados, os profissionais de segurança podem acelerar o processo de teste, garantir uma cobertura abrangente e facilitar a automação de tarefas repetitivas. Isso resulta em uma identificação mais rápida e precisa de vulnerabilidades, permitindo que as organizações tomem medidas proativas para fortalecer suas posturas de segurança cibernética.',
            '• OWASP – Open Web Application Security Project – Organizações dedicadas à segurança de aplicações web, oferecendo diretrizes e ferramentas para proteger contra ameaças.',
            '• PTES – Penetration Testing Execution Standard – Um padrão para conduzir testes de penetração de forma consistente e abrangente.',
            '• NIST – National Institute of Standards and Technology – É um framework desenvolvido pelo Breach Busters para testes de penetração e espionagem.'
        ]
    },
    {
        title: 'Projeto',
        content: [
            'O funcionamento do projeto ocorre de maneiras simples para o cliente e complexas nos bastidores. Conseguimos abstrair as partes difíceis em algo simples de se entender, permitindo que nossos clientes tenham o poder de tomar uma decisão assertiva.',
            '• ESCOPO – Você irá ver quais domínios estão em avaliação de testes, reagendando horas, dias e semanas, explicando o que há em algum cenário.',
            '• AVALIAÇÃO – Avaliação de que maneira poderiam ser implementados contra uma avaliação, usando nossas próprias técnicas de implantações realística.',
            '• RESULTADO – É agendada uma data/horário para gerar, BOA e INF, demandando serem traduzidas de forma clara para torná-las com dados de decisões e debates para o time técnico.'
        ]
    },
    {
        title: 'Report',
        content: [
            'Os relatórios oferecem uma comunicação clara e precisa dos resultados dos testes, ajudando a identificar facilmente vulnerabilidades críticas, priorizar correções e justificar investimentos em segurança. Além disso, fornecem informações valiosas para apoiar a tomada de decisões estratégicas, promovendo a segurança da informação dentro da organização. Com relatórios abrangentes e detalhados, você terá um visão clara dos riscos enfrentados e das medidas necessárias para proteger sua organização.',
            '• Relatórios Técnicos e Gerenciais – Contendo todas as etapas dos testes e suas sugestões.',
            '• Relatórios para Tomadores de Decisão – Exclusivos para os tomadores de decisão que necessitam de informação legível e eficiente.',
            '• Relatórios em PowerPoint – Apresentação de slides para que o nosso team possa explicar o que precisa ser feito, já com etapas e atividades dentro.'
        ]
    }
];

export const ethicalHackingContentRT = {
    title: "APIs e a facilidade de hackear.",
    description: "Entenda por que as APIs facilitam a vida dos agentes maliciosos. Se uma vez era normal realizar toda uma sequência de passos para obter informação sensível, hoje basta uma requisição.",
    buttonText: "VEJA COMO AS APIS SÃO ALVOS FÁCEIS",
    buttonLink: "/",
    backgroundLeft: "black",
    backgroundRight: "linear-gradient(to right, #3E4A89, #6B468C)"
};

export const soluctionLinksRT = [
    { href: '/Solutions/teste-de-intrusao', text: 'Teste de intrusão' },
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];

//Pishing
export const bgColorHeaderPishing = "bg-[#131112]";
export const linkHeaderPishing = "#contact";
export const textColorPishing = "text-white";
export const navLinksPishing = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];
export const soluctionLinksPishing = [
    { href: '/Solutions/teste-de-intrusao', text: 'Teste de intrusão' },
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/Red-Team', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];

//Treinamentos
export const TitleHomeTreinamentos = "Treinamentos";
export const bgColorHeaderTreinamentos = "bg-[#131112]";
export const bgColorHomeTreinamentos = "bg-[#131112]";
export const linkHeaderTreinamentos = "#contact";
export const textColorTreinamentos = "text-white"
export const subTitleTreinamentos = "";
export const navLinksTreinamentos = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];
export const homeTextTreinamentos = "Capacitar sua equipe é o primeiro passo para se protege contra ameaças invisíveis, com potenciais vulnerabilidades sendo exploradas por cibercriminosos, comprometendo seus clientes, parceiros e a operação do negócio. Para atender aos padrões regulatórios e proteger sua reputação, é crucial oferecer treinamentos em segurança cibernética. Educamos sua equipe sobre as melhores práticas de segurança, identificando praticas inadequadas e fazendo com que a segurança seja uma cultura dentro da sua empresa.";
export const soluctionLinksTreinamentos = [
    { href: '/Solutions/teste-de-intrusao', text: 'Teste de intrusão' },
    { href: '/Solutions/Red-Team', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];

export const formTextTreinamentos = "Ao capacitar sua equipe com conhecimento sobre ameaças cibernéticas e melhores práticas de segurança, você está investindo na segurança de sua empresa a longo prazo.";
export const formTitleTreinamentos = "Vamos treinar sua equipe!";

export const bannerItemsTreinamentos = [
    {
        icon: medical,
        title: '95%',
        description: 'das violações de segurança são resultado de erros humanos'
    },
    {
        icon: profile,
        title: '75%',
        description: 'menos propensos aos seus colaboradores ​cometeram erros que comprometam a ​segurança do seu ambiente ao serem ​conscientizados com treinamentos.'
    }
];

export const benefitsDataTreinamentos = {
    title: "Benefícios",
    description: "Ao fornecer treinamentos de segurança regulares para seus colaboradores, você pode aumentar a conscientização sobre práticas seguras de computação e proteger seus dados e os de seus parceiros. Além disso, treinamentos ajudam a avaliar e fortalecer a postura de segurança da sua empresa, garantindo o cumprimento de requisitos regulatórios e protegendo sua reputação contra potenciais violações de dados.",
    benefits: [
        {
            icon: IconOnline,
            title: 'Profissionais',
            description: 'É essencial contar com professores altamente qualificados e experientes, capazes de oferecer material de qualidade. A qualidade do treinamento impacta diretamente na capacidade da equipe de analisar seu próprio código e estruturar desde o início um código limpo e seguro. Isso não apenas aumentaria a conscientização sobre práticas seguras de computação, mas também fortaleceria a postura de segurança da empresa, garantindo um ambiente protegido contra potenciais ataques cibernéticos.',
            cardWidth: 'w-72',
            cardHeight: 'h-96',
        },
        {
            icon: IconSecurity,
            title: 'Segurança',
            description: 'Ao proporcionar uma formação abrangente e atualizada, não apenas aumentamos a conscientização sobre as melhores práticas de segurança, mas também promovemos uma cultura de segurança em toda a organização. Esse investimento não se limita à prevenção de ameaças cibernéticas, mas também abrange a correção de erros humanos e a mitigação de possíveis brechas de segurança. Com treinamentos contínuos e adaptados às necessidades específicas da empresa, garantimos que a equipe esteja preparada para os desafios em constante evolução do cenário de segurança cibernética.',
            cardWidth: 'w-72',
            cardHeight: 'h-96',
        },
        {
            icon: IconTimeline,
            title: 'Security By Design',
            description: 'Integrar a segurança desde o início do desenvolvimento é fundamental para garantir a proteção dos sistemas e dados. O conceito de Security by Design prioriza a implementação de medidas de segurança em todas as etapas do processo de desenvolvimento de software. Para isso, sua equipe precisa compreender as etapas que consistem nesse caminho. Nossos treinamentos cobrem não apenas esse conceito fundamental, mas também fornecem entendimento sobre por que erros teoricamente simples podem levar a ataques cibernéticos.',
            cardWidth: 'w-72',
            cardHeight: 'h-96',
        }
    ]
};


export const MethodologiesDataTreinamentos = [
    {
        title: 'Metodologia',
        content: [
            'Ao adotar frameworks consolidados, os profissionais de segurança podem acelerar o processo de teste, garantir uma cobertura abrangente e facilitar a automação de tarefas repetitivas. Isso resulta em uma identificação mais rápida e precisa de vulnerabilidades, permitindo que as organizações tomem medidas proativas para fortalecer suas posturas de segurança cibernética.',
            '• OWASP – Open Web Application Security Project – Organizações dedicadas à segurança de aplicações web, oferecendo diretrizes e ferramentas para proteger contra ameaças.',
            '• PTES – Penetration Testing Execution Standard – Um padrão para conduzir testes de penetração de forma consistente e abrangente.',
            '• NIST – National Institute of Standards and Technology – É um framework desenvolvido pelo Breach Busters para testes de penetração e espionagem.'
        ]
    },
    {
        title: 'Projeto',
        content: [
            'O funcionamento do projeto ocorre de maneiras simples para o cliente e complexas nos bastidores. Conseguimos abstrair as partes difíceis em algo simples de se entender, permitindo que nossos clientes tenham o poder de tomar uma decisão assertiva.',
            '• ESCOPO – Você irá ver quais domínios estão em avaliação de testes, reagendando horas, dias e semanas, explicando o que há em algum cenário.',
            '• AVALIAÇÃO – Avaliação de que maneira poderiam ser implementados contra uma avaliação, usando nossas próprias técnicas de implantações realística.',
            '• RESULTADO – É agendada uma data/horário para gerar, BOA e INF, demandando serem traduzidas de forma clara para torná-las com dados de decisões e debates para o time técnico.'
        ]
    }
];


export const ethicalHackingContentTreinamentos = {
    title: "APIs e a facilidade de hackear.",
    description: "Entenda por que as APIs facilitam a vida dos agentes maliciosos. Se uma vez era normal realizar toda uma sequência de passos para obter informação sensível, hoje basta uma requisição.",
    buttonText: "VEJA COMO AS APIS SÃO ALVOS FÁCEIS",
    buttonLink: "/",
    backgroundLeft: "black",
    backgroundRight: "linear-gradient(to right, #3E4A89, #6B468C)"
};

//Teste de estresse
export const bgColorHeaderTE = "bg-[#131112]";
export const linkHeaderTE = "#contact";
export const textColorTE = "text-white";
export const navLinksEstresse = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];
export const soluctionLinksTE = [
    { href: '/Solutions/Red-Team', text: 'Teste de intrusão' },
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];


//Terms
export const bgColorTerms = "bg-[#f8f4f4]";
export const linkContactTerms = "/#contact";
export const textColorTerms = "text-black";
export const titleBannerTerms = "Termos.";
export const navLinksTerms = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];
export const soluctionLinksTermos = [
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/red-team', text: 'Red Team' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
    { href: '/Solutions/teste-de-intrusao', text: 'Teste de Instrusão' }
];
export const DataTerms = [
    {
        title: "1. Do Termo",
        content: `
1.1 Este documento não constitui o termo final. O termo final será confeccionado de acordo com as especificações e pedidos do cliente.
        
1.2 Qualquer dúvida ou solicitação de esclarecimento deve ser encaminhada para compliance@breachbusters.com.br.
`
    },
    {
        title: "2. Dos Serviços",
        content: `
2.1 Os serviços ofertados podem não estar disponíveis no momento da contratação.

2.2 A maioria dos serviços é realizada manualmente por profissionais autorizados e certificados.

2.3 Não repassamos o trabalho para terceiros, exceto em casos onde utilizamos produtos licenciados de outros fornecedores.

2.4 Os valores dos serviços variam e devem ser consultados diretamente com o time comercial.
`
    },
    {
        title: "3. Do Quadro",
        content: `
3.1 O quadro de profissionais é certificado e composto por um time preparado e credenciado pela Breach Busters.

3.2 Não existe vínculo com terceiros para a realização dos serviços, a não ser que explicitamente descrito em contrato ou solicitado pelo cliente.
`
    },
    {
        title: "4. Da Propriedade Intelectual",
        content: `
4.1 Todos os dados recuperados do cliente são utilizados exclusivamente para a execução do trabalho ou serviço contratado.

4.2 Esses dados não são vendidos ou repassados a terceiros e são devidamente descartados após a conclusão do trabalho, salvo em casos de reteste acordado com o cliente.

4.3 A manipulação dos dados é coberta pela nossa política de privacidade e pelas leis locais aplicáveis.
`
    },
    {
        title: "5. Das Licenças",
        content: `
5.1 Os produtos utilizados em nossos serviços são licenciados, de código aberto ou desenvolvidos internamente pela Breach Busters.

5.2 Não utilizamos softwares ilegais ou suspeitos.

5.3 As informações fornecidas a ferramentas não intrusivas e que não necessitam de conhecimento do ambiente são anonimizadas.
`
    },
    {
        title: "6. Da Confidencialidade",
        content: `
6.1 A confidencialidade do projeto é mantida de ponta a ponta.

6.2 Não divulgamos informações sobre nossos clientes, salvo os casos previamente autorizados para campanhas de marketing, como a disposição de testemunhos no site.

6.3 Todos os dados são tratados de acordo com uma política de privacidade rígida e específica.
`
    }
];


//Privace
export const bgColorPrivace = "bg-[#f8f4f4]";
export const linkContactPrivace = "/#contact";
export const textColorPrivace = "text-black";
export const titleBannerPrivace = "Privacidade."
export const navLinksPrivace = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];
export const soluctionLinksPrivace = [
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/red-team', text: 'Red Team' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
    { href: '/Solutions/teste-de-intrusao', text: 'Teste de Instrusão' }
];

export const textPrivace = [
    {
        title: "",
        content: `A privacidade e a proteção de dados são pilares fundamentais em nossa ​empresa. Reconhecemos a importância de manter as informações de nossos ​clientes, fornecedores e stakeholders seguras e confidenciais. Esta cartilha visa ​esclarecer nossos compromissos e práticas relacionadas à privacidade e ao uso ​de dados.`
    },
    {
        title: "1. Privacidade do Cliente que Usa o Site",
        content: `
1.1. Coleta de Dados
Ao navegar em nosso site, coletamos dados básicos como endereço IP, tipo ​de navegador e páginas visitadas. Estes dados são utilizados para melhorar a ​experiência do usuário e personalizar nosso conteúdo.
1.2. Uso de Dados
Os dados coletados são utilizados exclusivamente com o consentimento do ​usuário, para fins de publicidade, propaganda e marketing. Garantimos que ​todas as informações são tratadas de forma confidencial e segura.
1.3. Proteção de Dados
Implementamos medidas rigorosas de segurança para proteger os dados ​dos usuários contra acessos não autorizados, uso indevido e divulgação.
`
    },
    {
        title: "2. Privacidade do Cliente que Contrata Nossos ​Serviços",
        content: `
2.1. Coleta de Dados
Ao contratar nossos serviços, coletamos informações pessoais e de contato, ​além de dados financeiros necessários para a execução do contrato.
2.2. Uso de Dados
Os dados fornecidos são utilizados somente para a prestação de serviços, ​atendimento ao cliente e atividades de marketing, sempre com o ​consentimento explícito do cliente.
2.3. Proteção de Dados
Nosso sistema de segurança robusto assegura que todas as informações dos ​clientes sejam protegidas contra violações e acessos não autorizados.
`
    },
    {
        title: "3. Privacidade dos Fornecedores",
        content: `
3.1. Coleta de Dados
Coletamos informações de contato e dados empresariais de nossos ​fornecedores para manter uma comunicação eficiente e realizar transações ​comerciais.
3.2. Uso de Dados
Os dados são utilizados para gerenciar contratos, realizar pagamentos e para ​atividades de marketing, sempre com o consentimento dos fornecedores.
3.3. Proteção de Dados
Adotamos práticas de segurança avançadas para garantir que os dados dos ​fornecedores sejam mantidos seguros e confidenciais.
`
    },
    {
        title: "4. Privacidade dos Stakeholders",
        content: `
4.1. Coleta de Dados
Coletamos informações pessoais e de contato de nossos stakeholders para ​manter uma comunicação contínua e transparente.
4.2. Uso de Dados
Os dados são utilizados para fins de comunicação, gestão de relacionamento ​e marketing, com o consentimento dos stakeholders.
4.3. Proteção de Dados
Garantimos a segurança dos dados dos stakeholders através de protocolos ​de segurança e políticas de privacidade estritas.
`
    },
    {
        title: "5. Consentimento e Uso de Dados",
        content: `
5.1. Coleta com Consentimento
Todos os dados coletados são obtidos com o consentimento explícito dos ​indivíduos. Nossos formulários e interfaces de coleta de dados incluem ​declarações claras sobre o uso das informações.
5.2. Finalidade dos Dados
Os dados são utilizados exclusivamente para publicidade, propaganda e ​marketing, sempre respeitando os limites do consentimento fornecido pelos ​usuários, clientes, fornecedores e stakeholders.
`
    },
    {
        title: "6. Compromisso com a Privacidade",
        content: `
6.1. Prioridade na Proteção de Dados
A privacidade e a segurança dos dados são nossas prioridades número um. ​Investimos continuamente em tecnologias e práticas para garantir que as ​informações pessoais e empresariais sejam protegidas contra qualquer tipo ​de ameaça.
6.2. Transparência e Confiabilidade
Mantemos um compromisso de transparência com todos os nossos clientes, ​fornecedores e stakeholders. Estamos sempre à disposição para esclarecer ​quaisquer dúvidas sobre nossas práticas de privacidade e proteção de dados.
`
    },
    {
        title: "",
        content: `A proteção da privacidade e dos dados é fundamental para nossa operação e ​sucesso. Reafirmamos nosso compromisso em manter suas informações ​seguras e confidenciais.`
    }
];

//Policy
export const bgColorPolicy = "bg-[#f8f4f4]";
export const linkContactPolicy = "/#contact";
export const textColorPolicy = "text-black";
export const titleBannerPolicy = "Politica.";
export const navLinksPolicy = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' },
  ];
export const soluctionLinksPolicy = [
    { href: '/Solutions/treinamentos', text: 'Treinamentos' },
    { href: '/Solutions/engenharia-reversa', text: 'Engenharia Reversa' },
    { href: '/Solutions/red-team', text: 'Red Team' },
    { href: '/Solutions/pishing', text: 'Phishing' },
    { href: '/Solutions/teste-de-estresse', text: 'Teste de estresse' },
];
export const textPolicy = [
    {
        title: "Compromisso com a Conformidade",
        content: `
A Breach Busters está comprometida em operar em conformidade com todas as leis, regulamentos e normas aplicáveis. Reconhecemos a importância da conformidade legal para manter a confiança dos clientes, parceiros e partes interessadas.
`
    },
    {
        title: "1. Proteção de Dados Pessoais",
        content: `
Entendemos a importância da proteção de dados pessoais e reconhecemos nossas responsabilidades sob a Lei Geral de Proteção de Dados (LGPD). Estamos comprometidos em proteger os dados pessoais dos nossos clientes e funcionários e em garantir que o tratamento desses dados seja realizado de acordo com os princípios estabelecidos na LGPD.
`
    },
    {
        title: "2. Certificação ISO 27001",
        content: `
Estamos em processo de busca pela certificação ISO 27001, que é uma norma internacionalmente reconhecida para sistemas de gestão de segurança da informação (SGSI). Nosso objetivo é implementar e manter um SGSI eficaz, que garanta a confidencialidade, integridade e disponibilidade das informações da organização.
`
    },
    {
        title: "3. Certificação ISO 27701",
        content: `
Estamos também em busca da certificação ISO 27701, que estende os requisitos do SGSI da ISO 27001 para incluir o gerenciamento da privacidade de informações pessoais. Nosso compromisso é garantir que as informações pessoais dos nossos clientes e funcionários sejam protegidas de acordo com os requisitos estabelecidos na ISO 27701.
`
    },
    {
        title: "4. Responsabilidade da Administração",
        content: `
A administração da empresa assume a responsabilidade pelo estabelecimento, implementação, manutenção e melhoria contínua dos processos relacionados à conformidade, proteção de dados e segurança da informação. Comprometemo-nos a fornecer os recursos necessários e o apoio adequado para alcançar esses objetivos.
`
    },
    {
        title: "5. Monitoramento e Revisão",
        content: `
Nosso compromisso com a conformidade, proteção de dados e segurança da informação será monitorado e revisado regularmente para garantir que continuemos a atender aos requisitos regulamentares e normativos aplicáveis e a manter os mais altos padrões de excelência em todas as nossas operações.
`
    },
    {
        title: "6. Compliance e Conduta",
        content: `
Todos os profissionais da nossa empresa passam por treinamentos regulares em ética empresarial. Esses treinamentos visam fornecer orientação sobre as políticas, procedimentos e práticas éticas da empresa, bem como promover a conscientização sobre dilemas éticos comuns no ambiente de trabalho.
`
    },
    {
        title: "7. Termos de Comprometimento Ético",
        content: `
Todos os profissionais são obrigados a assinar termos de comprometimento ético, nos quais concordam em aderir aos padrões éticos estabelecidos pela empresa. Esses termos destacam a importância da integridade, honestidade, transparência e respeito nas interações profissionais.
`
    },
    {
        title: "8. Avaliação da Conduta Ética",
        content: `
A conduta ética dos profissionais é avaliada constantemente como parte do nosso processo de gestão de desempenho. Através de avaliações regulares, monitoramos o cumprimento dos padrões éticos e identificamos oportunidades de melhoria.
`
    },
    {
        title: "9. Responsabilidade Individual",
        content: `
Cada profissional é responsável por sua própria conduta ética, bem como por promover um ambiente de trabalho ético e respeitoso para seus colegas. Encorajamos a comunicação aberta e a denúncia de quaisquer comportamentos antiéticos ou violações das políticas da empresa.
`
    },
    {
        title: "10. Medidas Disciplinares",
        content: `
Violações das políticas éticas da empresa são levadas a sério e podem resultar em medidas disciplinares, incluindo advertências, suspensões ou rescisões de contrato, conforme a gravidade da infração.
`
    },
    {
        title: "11. Conformidade com Leis e Normas",
        content: `
Todos os colaboradores são obrigados a agir em conformidade com as leis e regulamentações aplicáveis, bem como as normas éticas e do mercado. Não toleramos qualquer forma de conduta ilegal ou antiética por parte dos nossos colaboradores.
`
    },
    {
        title: "12. Integridade e Transparência",
        content: `
Espera-se que os colaboradores ajam com integridade e transparência em todas as suas interações profissionais. Não permitimos o uso de má fé ou comportamento desonesto por parte dos nossos colaboradores.
`
    },
    {
        title: "13. Atividades Dentro do Escopo",
        content: `
Os colaboradores são instruídos a realizar apenas as atividades que estão dentro do escopo contratado e apropriado para suas funções. Qualquer atividade fora do escopo contratado deve ser explicitamente autorizada pela empresa.
`
    },
    {
        title: "14. Medidas Disciplinares por Violação",
        content: `
Os colaboradores que violarem estas diretrizes serão sujeitos a advertências e medidas disciplinares, conforme necessário. A conduta dos colaboradores é monitorada regularmente para garantir conformidade com estas diretrizes.
`
    },
    {
        title: "15. Termos e Condições dos Contratos",
        content: `
Os colaboradores são orientados a seguir rigorosamente os termos e condições estabelecidos nos contratos firmados com clientes e parceiros. Nenhuma atividade deve ser realizada sem autorização prévia ou explicitamente definida no contrato.
`
    },
    {
        title: "16. Compromisso com a Privacidade",
        content: `
Reconhecemos a importância da privacidade dos dados e estamos comprometidos em proteger as informações confidenciais dos nossos colaboradores, clientes e parceiros. Todos os dados pessoais e informações sensíveis serão tratados com o mais alto nível de confidencialidade e segurança.
`
    },
    {
        title: "17. Coleta e Uso de Dados",
        content: `
Os dados pessoais e informações dos colaboradores, clientes e parceiros serão coletados apenas para fins específicos e legítimos, e serão utilizados apenas conforme necessário para o cumprimento de contratos e obrigações legais. Não compartilharemos, venderemos ou alugaremos informações pessoais ou sensíveis a terceiros sem o consentimento explícito dos titulares dos dados.
`
    },
    {
        title: "18. Armazenamento e Segurança dos Dados",
        content: `
Os dados pessoais serão armazenados de forma segura e protegidos contra acesso não autorizado, uso indevido ou divulgação. Implementaremos medidas técnicas e organizacionais adequadas para garantir a segurança dos dados e prevenir violações de segurança.
`
    },
    {
        title: "19. Direitos dos Titulares dos Dados",
        content: `
Os titulares dos dados têm o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais, conforme permitido por lei. Envidaremos todos os esforços razoáveis para garantir que os titulares dos dados possam exercer esses direitos de forma eficaz.
`
    },
    {
        title: "20. Transparência nas Práticas de Dados",
        content: `
Forneceremos transparência sobre nossas práticas de coleta, uso e compartilhamento de dados, e comunicaremos de forma clara e transparente sobre quaisquer mudanças em nossa política de privacidade.
`
    },
    {
        title: "21. Conformidade com Leis de Proteção de Dados",
        content: `
Comprometemo-nos a cumprir todas as leis e regulamentos aplicáveis relacionados à proteção de dados, privacidade e segurança da informação. A privacidade dos dados é uma prioridade para nossa empresa e estamos comprometidos em proteger as informações pessoais e sensíveis dos nossos colaboradores, clientes e parceiros.
`
    },
    {
        title: "22. Revisão e Aprimoramento",
        content: `
Continuaremos a revisar e aprimorar nossas práticas de privacidade e proteção de dados para garantir conformidade com as melhores práticas e padrões do setor.
`
    }
];


//404
export const bgColor404 = "bg-[#f8f4f4]";
export const linkContact404 = "/#contact";
export const textColor404 = "text-black";
export const navLinks404 = [
    { text: 'Sobre', href: '/About' },
    { text: 'Blog', href: '#blog', scrollToId: 'blog' },
    { text: 'Soluções', href: '/#solutions', scrollToId: 'solutions' }
  ];