import React from 'react';
import { FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import skeletonLogo from '../../Images/BREACH_BUSTER_HACKING_CLUB__1_-removebg-preview.webp';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-2 flex flex-col items-center" style={{ fontFamily: 'Tomorrow, sans-serif' }}>
      <div className="md:m-64 md:mb-0 md:mt-10 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col items-center md:items-start text-center md:text-left md:mb-0">
            <a href="/">
              <div className="flex justify-center md:justify-start mb-4 items-center space-x-4">
                <img src={skeletonLogo} alt="Logo" className="w-1/4 h-auto md:w-1/6 md:h-1/3" />
                <div className="flex flex-col items-center md:items-start">
                  <span className="text-lg md:text-3xl lg:text-4xl font-bold leading-none" style={{ fontFamily: 'Tomorrow, sans-serif' }}>Breach</span>
                  <span className="text-lg md:text-3xl lg:text-4xl font-bold leading-none" style={{ fontFamily: 'Tomorrow, sans-serif' }}>Busters</span>
                </div>
              </div>
            </a>
            <div className="mt-2" style={{ fontFamily: 'Montserrat, sans-serif' }}>
              <h3 className="text-[#d11c9b] text-lg font-bold">Whatsapp</h3>
              <a href="https://wa.me/554199092004" className="text-xl font-bold text-white no-underline">
                +55 41 9909-2004
              </a>
              <h3 className="mt-2 text-[#d11c9b] text-lg font-bold">Email</h3>
              <a href="mailto:comercial@breachbusters.com.br" className="text-xl font-bold text-white no-underline">
                comercial@breachbusters.com.br
              </a>
            </div>

          </div>
          <div className="grid grid-cols-1 gap-5 mt-2 md:mt-0 md:flex md:justify-start text-center md:text-left" style={{ fontFamily: 'Anonymous Pro, sans-serif' }}>
            <div className="mx-3 mb-4 md:mb-0">
              <h4 className="font-bold text-[#d11c9b] text-lg">Sobre</h4>
              <ul>
                <li><a href="/About" className="hover:underline">About</a></li>
              </ul>
            </div>
            <div className="mx-3 mb-4 md:mb-0">
              <h4 className="font-bold text-[#d11c9b] text-lg" >Blog</h4>
              <ul>
                <li>News</li>
                <li>Tips</li>
              </ul>
            </div>
            <div className="mx-3 mb-4 md:mb-0">
              <h4 className="font-bold text-[#d11c9b] text-lg">Soluções</h4>
              <ul>
                <li><a href="/Solutions/teste-de-intrusao" className="hover:underline">Teste de Intrusão</a></li>
                <li><a href="/Solutions/Red-Team" className="hover:underline">Red Team</a></li>
                <li><a href="/Solutions/treinamentos" className="hover:underline">Treinamentos</a></li>
                <li><a href="/Solutions/pishing" className="hover:underline">Phishing</a></li>
                <li><a href="/Solutions/teste-de-estresse" className="hover:underline">Teste De Estress</a></li>
                <li><a href="/Solutions/Engenharia-reversa" className="hover:underline">Engenharia reversa</a></li>
              </ul>
            </div>
            <div className="mx-3 mb-4 md:mb-0">
              <h4 className="font-bold text-[#d11c9b] text-lg">Hire Us</h4>
              <ul>
                <li><a href="https://www.linkedin.com/company/breachbusters/" className="hover:underline">Work with us</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center md:items-start mt-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
          <div className="flex space-x-4 mb-4 justify-center md:justify-start w-full">
            <a href="https://www.linkedin.com/company/breachbusters/"><FaLinkedin className="text-xl cursor-pointer" /></a>
            <a href="https://www.youtube.com/channel/UCKwSFG9AgF-33Met_kdiG0Q "><FaYoutube className="text-xl cursor-pointer" /></a>
            <a href=":https://www.instagram.com/breachbusters"><FaInstagram className="text-xl cursor-pointer" /></a>
          </div>
          <div className="w-full py-4 text-center text-sm text-gray-500 border-t-4 border-white mt-2 flex flex-col md:flex-row justify-center md:justify-between space-y-2 md:space-y-0">
            <div className="flex flex-wrap justify-center md:justify-start space-x-2">
              <a href="/Policy" className="hover:underline">Política</a>
              <a href="/Terms" className="hover:underline">Termos</a>
              <a href="/Privacy" className="hover:underline">Privacidade</a>
            </div>
            <p>&copy; Breach Busters todos os direitos reservados</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
