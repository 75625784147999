import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Pages/Main';
import TesteDi from './Pages/TesteDeIntrusao';
import EngRev from './Pages/EngenhariaReversa';
import About from './Pages/About';
import RedTeam from './Pages/RedTeam';
import Treinamento from './Pages/Treinamento';
import Phishing from './Pages/Pishing';
import TesteDeEstresse from './Pages/TesteDeEstresse';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Policy from './Pages/Policy';
import ErrorPage from './Pages/ErrorPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/About" element={<About />} />
        <Route path="/Solutions/teste-de-intrusao" element={<TesteDi />} />
        <Route path="/Solutions/Engenharia-reversa" element={<EngRev />} />
        <Route path="/Solutions/Red-Team" element={<RedTeam />} />
        <Route path="/Solutions/treinamentos" element={<Treinamento />} />
        <Route path="/Solutions/pishing" element={<Phishing />} />
        <Route path="/Solutions/teste-de-estresse" element={<TesteDeEstresse />} />
        <Route path="/Terms" element={<Terms />} /> 
        <Route path="/Privacy" element={<Privacy />} /> 
        <Route path="/Policy" element={<Policy />} /> 
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
