import React from 'react';

const TextInfo = ({ sections }) => {
    if (!Array.isArray(sections)) {
        return null;
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-[#f8f4f4] text-black">
            <div className="w-full max-w-3xl p-4 ">
                {sections.map((section, index) => (
                    <div key={index} className="mb-6">
                        <h1 className="text-3xl font-bold mb-4" style={{ fontFamily: ' Montserrat , sans-serif' }}>{section.title}</h1>
                        <pre className="whitespace-pre-wrap text-justify" style={{ fontFamily: ' Montserrat , sans-serif' }}>{section.content}</pre>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TextInfo;
