import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/swiper-bundle.css';
import { Pagination, Autoplay } from 'swiper/modules';

const SoluctionCards = ({ soluctionData }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div id="solutions" className="md:max-w-screen-xl mx-auto bg-[#f4f4f4] text-black mt-10 px-1 lg:px-24 m-14" style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <div className="py-1 max-w-4xl mx-auto m-4">
                <div className="text-center mb-4">
                    <h1 className="text-3xl font-bold">Soluções</h1>
                    <p className="text-lg">
                        Realize testes e forneça sistemas de <strong>alta qualidade e segurança.</strong>
                        Simplifique o processo desde o desenvolvimento até a produção.
                        Obtenha insights profundos, identifique vulnerabilidades, priorize
                        brechas e corrija erros rapidamente.
                    </p>
                </div>
                {isMobile ? (
                    <Swiper
                        className="m-0"
                        modules={[Autoplay, Pagination]}
                        spaceBetween={10}
                        loop={true}
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        pagination={{ clickable: true }}
                    >
                        {soluctionData.map(card => (
                            <SwiperSlide key={card.id} className="flex justify-center">
                                <a href={card.link} className="flex flex-col items-center p-4 bg-[#808080] border border-transparent shadow-lg rounded-md transition duration-300 hover:shadow-[#D01B9B]">
                                    <p className="text-lg text-center font-semibold">{card.title}</p>
                                    <div className="flex items-center">
                                        <img
                                            src={card.image}
                                            alt={`Imagem ${card.id}`}
                                            className="w-20 h-20 object-cover rounded-md"
                                        />
                                        <p className="ml-4 text-md">{card.description}</p>
                                    </div>
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:gap-8 justify-items-center">
                        {soluctionData.map(card => (
                            <a
                                key={card.id}
                                href={card.link}
                                className="rounded-md p-4 flex bg-[#808080] border border-transparent shadow-lg transition duration-300 justify-center hover:shadow-[#D01B9B]"
                            >
                                <div className="flex flex-col justify-center">
                                    <p className="text-lg text-center font-semibold m-2">{card.title}</p>
                                    <div className="flex items-center">
                                        <img
                                            src={card.image}
                                            alt={`Imagem ${card.id}`}
                                            className="w-20 h-20 object-cover rounded-md"
                                        />
                                        <p className="text-md text-center">{card.description}</p>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                )}
            </div>
            <div className="soluction-button text-center mb-2">
                <a
                    href="#contact"
                    className="px-8 py-2 text-lg font-bold bg-[#000] shadow-md hover:shadow-[#D01B9B] text-white rounded-lg m-4">
                    BECOME BREACHLESS
                </a>
            </div>
        </div>
    );
};

export default SoluctionCards;
