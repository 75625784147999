import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';

const BenefictsServices = ({ benefitsData }) => {
  const cardClass = `bg-opacity-10 backdrop-filter backdrop-blur-lg border border-gray-200 p-4 rounded-lg shadow-lg transition transform hover:scale-105`;

  return (
    <div className="flex flex-col justify-center items-center bg-[#f4f4f4] text-black py-16" style={{ fontFamily: 'Montserrat, sans-serif' }}>
      <div className="w-full max-w-screen-lg px-4 md:px-0">
        <h2 className="text-2xl font-bold text-center mb-6">{benefitsData.title}</h2>
        <p className="text-center mb-12 mx-4 lg:px-48">
          {benefitsData.description}
        </p>

        <div className="block md:hidden mb-20 px-4">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            pagination={{ clickable: true, el: '.swiper-pagination-custom' }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Pagination, Autoplay]}
          >
            {benefitsData.benefits.map((benefit, index) => (
              <SwiperSlide key={index}>
                <div className={`bg-gray-800 text-black p-6 mx-auto ${cardClass}`}>
                  <h3 className="text-xl font-bold mb-1 text-center">{benefit.title}</h3>
                  <img src={benefit.icon} alt={benefit.title} className="w-12 h-12 md:w-20 md:h-20 mx-auto mb-4" />
                  <p className="text-center">{benefit.description}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination-custom mt-4 flex justify-center"></div> 
        </div>

        <div className="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8 px-4 md:px-8 lg:px-16">
          {benefitsData.benefits.map((benefit, index) => (
            <div key={index} className={`bg-gray-800 text-black p-6 ${cardClass}`}>
              <h3 className="text-xl font-bold mb-1 text-center">{benefit.title}</h3>
              <img src={benefit.icon} alt={benefit.title} className="w-14 h-14 mx-auto mb-4" />
              <p className="text-center">{benefit.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <a href="#contact" className="px-8 py-2 text-lg font-bold bg-[#000] shadow-md hover:shadow-[#D01B9B] text-white rounded-lg">
            BECOME BREACHLESS
          </a>
        </div>
      </div>
    </div>
  );
};

export default BenefictsServices;
