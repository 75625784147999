import BenefictsServices from "../Components/BenefictsServices/BenefictsServices";
import BannerMain from '../Components/BannerMain/BannerMain';
import Header from "../Components/Header/Header";
import Methodologies from "../Components/Methodologies/Methodologies";
import Contact from "../Components/Contact/Contact";
import { MethodologiesDataER, MethodologiesDataTI, TitleHomeER, benefitsDataER, bgColorERHeader, bgColorERHome, ethicalHackingContentER, formTextER, formTitleER, homeTextER, linkHeaderER, navLinksEngenharia, soluctionLinksER, textColorER } from "./Config";
import BannerServices from "../Components/BannerServices/BannerServices";
import Footer from "../Components/Footer/Footer";
import logo from './../Images/BREACH_BUSTER_HACKING_CLUB__1_-removebg-preview.webp'

const EngenhariaReversa = () => {
    return (
        <>
            <Header logo={logo}
                bgColor={bgColorERHeader}
                linkContact={linkHeaderER}
                textColor={textColorER}
                soluctionLinks={soluctionLinksER}
                logoSize="md:w-25 md:h-25 md:p-2 w-20 h-20"
                navLinks={navLinksEngenharia} />
            <BannerMain bgColor={bgColorERHome}
                title={TitleHomeER}
                text={homeTextER} />
            <BenefictsServices benefitsData={benefitsDataER} />
            <Methodologies content={MethodologiesDataER} />
            <Contact textForm={formTextER}
            titleForm={formTitleER}/>
            <BannerServices {...ethicalHackingContentER} />
            <Footer />
        </>
    )
}

export default EngenhariaReversa;