import React from 'react';
import backgroundImage from '../../Images/404-fuzz.jfif.png'; 

const ErrorComponent = () => {
    return (
        <div className="relative h-screen w-full flex items-center justify-center bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="absolute bg-opacity-50 flex items-center justify-center w-full h-full">
                <div className="text-center p-8 rounded-lg bg-white bg-opacity-10 backdrop-blur-md shadow-lg m-10 mt-0 mb-0">
                    <h1 className="text-4xl font-bold text-black mb-4">
                        Procura-se essa página
                    </h1>
                    <p className="text-xl text-black mb-4">
                        Não se preocupe, vamos encontrá-la!
                    </p>
                    <a href="/" className="inline-block bg-black text-white font-semibold px-6 py-3 rounded hover:bg-white hover:text-black transition duration-300">
                        HOME
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ErrorComponent;
