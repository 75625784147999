import BannerSoluctions from '../Components/BannerSoluctions/BannerSoluctions';
import BenefictsServices from '../Components/BenefictsServices/BenefictsServices';
import Header from '../Components/Header/Header';
import Methodologies from '../Components/Methodologies/Methodologies';
import Contact from '../Components/Contact/Contact';
import BannerMain from '../Components/BannerMain/BannerMain';
import BannerServices from '../Components/BannerServices/BannerServices';
import Footer from '../Components/Footer/Footer';
import logo from './../Images/BREACH_BUSTER_HACKING_CLUB__1_-removebg-preview.webp'
import { TitleHomeRT, bannerItemsRT, benefitsDataRT, bgColorERHome, bgColorRTHeader, homeTextRT, linkHeaderRT, MethodologiesDataRT, ethicalHackingContentRT, textColorRT, soluctionLinksRT, logoHeaderRT, navLinksRedTeam, formTextER, formTitleRD, formTextRD } from './Config';

const RedTeam = () => {
    return (
        <>
            <Header logo={logo}
                bgColor={bgColorRTHeader}
                linkContact={linkHeaderRT}
                textColor={textColorRT}
                soluctionLinks={soluctionLinksRT} 
                logoSize="md:w-25 md:h-25 md:p-2 w-20 h-20" 
                navLinks={navLinksRedTeam}/>
            <BannerMain bgColor={bgColorERHome}
                title={TitleHomeRT}
                text={homeTextRT} />
            <BannerSoluctions bannerItems={bannerItemsRT} />
            <BenefictsServices benefitsData={benefitsDataRT} />
            <Methodologies content={MethodologiesDataRT} />
            <Contact textForm={formTextRD}
            titleForm={formTitleRD}/>
            <BannerServices {...ethicalHackingContentRT} />
            <Footer />
        </>
    );
}

export default RedTeam;