
import React from 'react';
import Benefits from '../Components/Benefits/Benefits';
import Contact from '../Components/Contact/Contact';
import Header from '../Components/Header/Header';
import Soluctions from '../Components/Soluctions/Soluctions';
import SoluctionCards from '../Components/SoluctionsCards/SoluctionCards';
import Footer from '../Components/Footer/Footer';
import logo from './../Images/BREACH_BUSTER_HACKING_CLUB__1_-removebg-preview.webp';
import ClientCarrossel from '../Components/ClientCarrosel/ClienteCarrosel';
import HomeMain from '../Components/HomeMain/HomeMain';
import CertificationsCarousel from '../Components/CertificationsCarousel/CertificationsCarousel';
import { bgColorHeader, bgColorHome, formTextMain, formTitleMain, homeText, homeTitle, homeTitleOne, homeTitleTwo, linkHeader, logoHeaderHome, navLinksHome, soluctionCards, soluctionLinks, subTitle, textColorHeader } from './Config';

function Main() {
  return (
    <div className="bg-[#f4f4f4]">
      <Header logo={logo}
        bgColor={bgColorHeader}
        linkContact={linkHeader}
        soluctionLinks={soluctionLinks}
        textColor={textColorHeader}
        logoSize="md:w-25 md:h-25 md:p-2 w-20 h-20" 
        navLinks={navLinksHome}/>
      <HomeMain bgColor={bgColorHome}
        titleOne={homeTitleOne}
        titleTwo={homeTitleTwo}
        subTitle={subTitle}
        text={homeText} />
      <Soluctions />
      <SoluctionCards soluctionData={soluctionCards} />
      <Benefits />
      <CertificationsCarousel />
      <Contact textForm={formTextMain}
      titleForm={formTitleMain}/>
      {/* <ClientCarrossel /> */}
      <Footer />
    </div>
  );
}

export default Main;
