import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import icone1 from '../../Images/telescope.png';
import icone2 from '../../Images/lock.png';
import icone3 from '../../Images/choices.png';

const Soluctions = () => {
    const cards = [
        {
            img: icone1,
            title: "Visibilidade",
            text: "Tenha visibilidade das brechas e vulnerabilidades do seu ambiente para tomar medidas proativas e mitigar possíveis riscos de segurança."
        },
        {
            img: icone2,
            title: "Segurança",
            text: "Implemente práticas de segurança avançadas para proteger seus sistemas contra ameaças cibernéticas, garantindo a integridade, confidencialidade e disponibilidade dos dados."
        },
        {
            img: icone3,
            title: "Controle",
            text: "Retome o controle do seu negócio, recupere o poder de direcionar os investimentos de segurança protegendo o que é mais valioso para sua empresa."
        }
    ];

    return (
        <div className="flex flex-col items-center bg-[#f4f4f4] text-black mt-8 md:m-52 md:mt-10 md:mb-10">
            <h1 className="items-center text-4xl font-bold mb-2 mt-0 text-center px-2 md:m-20 md:mt-1 md:mb-1" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                Soluções personalizáveis Para <span className="text-[#d11c9b]">Todas</span> As Empresas
            </h1>
            <div className="w-full max-w-screen-lg px-4 text-xl">
                <Swiper
                    spaceBetween={25}
                    slidesPerView={1}
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                        },
                    }}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                >
                    {cards.map((card, index) => (
                        <SwiperSlide key={index}>
                            <div className="text-center p-5 rounded-lg transition duration-300 bg-[#f4f4f4]">
                                <div className="z-10 rounded-lg bg-[#f4f4f4] hover:bg-[#888484] hover:bg-opacity-10 hover:backdrop-filter hover:backdrop-blur-lg hover:border hover:border-white hover:border-opacity-20 hover:shadow-xl">
                                    <div className="p-5">
                                        <div className="mb-5">
                                            <img
                                                src={card.img}
                                                alt={`Ícone ${index + 1}`}
                                                className="h-28 w-28 object-cover mx-auto mb-2"
                                            />
                                        </div>
                                        <h2 className="text-lg font-semibold mb-2" style={{ fontFamily: 'Montserrat, sans-serif' }}>{card.title}</h2>
                                        <p style={{ fontFamily: 'Montserrat, sans-serif' }}>{card.text}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default Soluctions;
