import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import BannerInfo from "../Components/BannerInfo/BannerInfo";
import TextInfo from "../Components/TextInfo/TextInfo";
import { bgColorPrivace, linkContactPrivace, navLinksPrivace, soluctionLinksPrivace, textColorPrivace, textPrivace, titleBannerPrivace } from "./Config";
import logo from './../Images/Icone_Fundo_Transparente.webp';

const Privace = () => {
    return (
        <>
            <Header logo={logo}
                bgColor={bgColorPrivace}
                textColor={textColorPrivace}
                linkContact={linkContactPrivace}
                soluctionLinks={soluctionLinksPrivace}
                logoSize="w-15 h-20 p-2" 
                navLinks={navLinksPrivace}/>
            <BannerInfo
                title={titleBannerPrivace}
                textColor={textColorPrivace} />
            <TextInfo sections={textPrivace} />
            <Footer />
        </>
    )
}

export default Privace;