import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import BannerMain from "../Components/BannerMain/BannerMain";
import logo from './../Images/BREACH_BUSTER_HACKING_CLUB__1_-removebg-preview.webp';
import { bannerItemsTreinamentos, benefitsDataTreinamentos, bgColorHeaderTreinamentos, bgColorHomeTreinamentos, ethicalHackingContentTreinamentos, formTextTreinamentos, formTitleTreinamentos, homeTextTreinamentos, linkHeaderTreinamentos, MethodologiesDataTreinamentos, navLinksTreinamentos, soluctionLinksTreinamentos, textColorTreinamentos, TitleHomeTreinamentos } from "./Config";
import BannerSoluctions from "../Components/BannerSoluctions/BannerSoluctions";
import BenefictsServices from "../Components/BenefictsServices/BenefictsServices";
import Methodologies from "../Components/Methodologies/Methodologies";
import Contact from "../Components/Contact/Contact";
import BannerServices from "../Components/BannerServices/BannerServices";

const Treinamento = () => {
    return (
        <>
            <Header logo={logo}
                bgColor={bgColorHeaderTreinamentos}
                linkContact={linkHeaderTreinamentos}
                textColor={textColorTreinamentos}
                soluctionLinks={soluctionLinksTreinamentos}
                logoSize="md:w-25 md:h-25 md:p-2 w-20 h-20" 
                navLinks={navLinksTreinamentos}/>
            <BannerMain bgColor={bgColorHomeTreinamentos}
                title={TitleHomeTreinamentos}
                text={homeTextTreinamentos} />
            <BannerSoluctions bannerItems={bannerItemsTreinamentos}/>
            <BenefictsServices  benefitsData={benefitsDataTreinamentos} />
            <Methodologies content={MethodologiesDataTreinamentos} />
            <Contact textForm={formTextTreinamentos}
            titleForm={formTitleTreinamentos}/>
            <BannerServices {...ethicalHackingContentTreinamentos}/>
            <Footer />
        </>
    )
}

export default Treinamento;