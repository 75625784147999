import React from 'react';

const BlackSection = () => {
    return (
        <div className="bg-[#000000]" style={{ fontFamily: 'Poppins, sans-serif' }}>
            <div className='mx-4 sm:mx-8 md:mx-20 lg:mx-40 mt-0 mb-0 bg-black text-white py-12 px-6 text-center'>
                <h2 className="text-2xl md:text-5xl font-bold m-14 mt-0 mb-4">Vamos incrementar sua segurança!</h2>
                <p className="text-lg md:text-2xl mb-2">
                    Reduza o risco da sua empresa, conte com profissionais dedicados e experts.
                </p>
                <p className="text-lg md:text-2xl mb-6">
                    Entre em contato para tomar a melhor decisão!
                </p>
                <div className="mt-4">
                    <a
                        href="/#contact"
                        className="bg-[#d11c9b] text-black font-bold py-2 px-8 rounded-full"
                    >
                        BECOME BREACHLESS
                    </a>
                </div>
            </div>
        </div>
    );
}

export default BlackSection;
