import React, { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

const Methodologies = ({ content }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleContent = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-[#f4f4f4] py-16 flex justify-center">
      <div className="w-full max-w-6xl px-4">
        {content.map((section, index) => {
          const isReverse = index % 2 !== 0;
          const isOpen = openIndex === index;

          return (
            <div
              key={index}
              className={`flex flex-col items-center md:flex-row ${isReverse ? 'md:flex-row-reverse' : ''} my-4`}
              style={{ fontFamily: 'Montserrat, sans-serif' }}
            >
              <div className="w-full md:w-1/4 flex justify-center md:justify-start mb-4 md:mb-0">
                {/* Add image or content here if needed */}
              </div>
              <div className="w-full md:w-2/3 bg-[#131112] p-6 rounded-lg shadow-lg">
                <div className="flex justify-between items-center cursor-pointer md:cursor-default" onClick={() => toggleContent(index)}>
                  <h3 className="text-xl font-bold mb-4 text-white">
                    {section.title}
                  </h3>
                  <div className="md:hidden text-white">
                    {isOpen ? <HiChevronUp className="w-8 h-8" /> : <HiChevronDown className="w-8 h-8" />}
                  </div>
                </div>
                <div className={`md:block ${isOpen ? 'block' : 'hidden'}`}>
                  {section.content.map((text, idx) => (
                    <p key={idx} className="text-white mb-2">{text}</p>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Methodologies;
