import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

import lpic from '../../Images/linkedin_thumb_cisco_ccna_security.png';
import ewptx from '../../Images/1_DXi-mIjzXSs0y877pq3_nA.png';
import oscp from '../../Images/LPI_LPIC1.png';
import ceh from '../../Images/1_twzm1hsLz21C4-_0Id1jaQ.png';
import emapt from '../../Images/1__Io_BddLsgkctxWKp6KH_Q.png';
import ccna from '../../Images/api-pentest-removebg-preview.png';
import ans from '../../Images/OSCP.png';

const CertificationsCarousel = () => {
  return (
    <div className="flex flex-col items-center justify-center my-8 mx-4 md:mx-80 md:mb-32 md:mt-8 text-black">
      <h2 className="text-3xl font-bold mb-10">Certificações</h2>
      <p className="text-xl m-0 mb-8 mt-0 text-center">
        Você irá contar com um time que não só possui experiência em diversas áreas, mas também um time com conhecimento comprovado pelas maiores certificadoras do mercado!
      </p>
      <div className="swiper-container w-full max-w-6xl">
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={10}
          loop={true}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
        >
          <SwiperSlide>
            <img src={lpic} alt="LPIC-1" className="mx-auto w-24 h-24 mt-4 mb-10" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ewptx} alt="eWPTX" className="mx-auto w-24 h-24 mt-4 mb-10" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={oscp} alt="OSCP" className="mx-auto w-24 h-24 mt-4 mb-10" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ceh} alt="CEH" className="mx-auto w-24 h-24 mt-4 mb-10" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={emapt} alt="eMAPT" className="mx-auto w-24 h-24 mt-4 mb-10" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ccna} alt="CCNA" className="mx-auto w-24 h-24 mt-4 mb-10" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ans} alt="ans" className="mx-auto w-24 h-24 mt-4 mb-10" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default CertificationsCarousel;
