import ErrorComponent from '../Components/ErrorMsg/ErrorMsg';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import logo from './../Images/Icone_Fundo_Transparente.webp';
import { bgColor404, linkContact404, navLinks404, textColor404 } from './Config';

const ErrorPage = () => {
    return (
        <>
            <Header bgColor={bgColor404}
                logo={logo}
                linkContact={linkContact404}
                textColor={textColor404} 
                logoSize="w-15 h-16"
                navLinks={navLinks404}/>
            <ErrorComponent />
            <Footer />
        </>
    )
}

export default ErrorPage;